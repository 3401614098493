<template>
  <b-card body-class="p-1rem m-0" class="mb-0 w-100 hvh-100">
    <div 
      class="max-height-area-options"
    >
      <div class="d-flex justify-content-between">
        <component
          :is="vue_select"
          class="select-options-ai select-options-ai-setting col-8 p-0 select-option-ia-model"
          :options="$t('search.ia_options')"
          v-model="setting_selected.ia_selected"
          :disabled="loader_image_search"
        >
          <template #option="{ label, badge }">
            <span class="avenir-medium">{{ label }} <b-badge variant="success" class="ml-05" v-if="badge">{{ badge }}</b-badge></span>
          </template>
        </component>

        <component
          :is="vue_select" 
          class="select-options-ai select-options-ai-setting col-4 p-0 pl-1"
          :options="number_image"
          id="number-image-select"
          v-model="setting_selected.number_image_ai"
          :disabled="loader_image_search"
        >
        </component>
      </div>

      <b-form-input :placeholder="$t('search.negativePromp')" autocomplete="off" class="mt-1 mb-1" :disabled="loader_image_search || !!setting_selected.file_variants"  v-model="setting_selected.negative_prompt"/>      
      <div v-if="setting_selected.ia_selected.value !== 'imagine'">
        <b-button-group class="w-100 mb-1">
          <b-button 
            @click="tabs_options = 0" 
            :class="{'active': tabs_options === 0}" 
            class="button-tabs-options-ia mr-1" 
            variant="button-tabs-options-ia"
            :disabled="!!setting_selected.file_variants"
          >
            {{$t('ia.image.promptBulder')}}
          </b-button>
          <b-button @click="tabs_options = 1" :class="{'active': tabs_options === 1}" class="button-tabs-options-ia" variant="button-tabs-options-ia">{{$t('ia.image.modelOptions')}}</b-button>
          <b-button @click="open_sidebar_text_prompt = true" class="button-tabs-options-ia ml-1" variant="button-tabs-options-ia"><feather-icon icon="HelpCircleIcon"></feather-icon></b-button>
        </b-button-group>
  
        <!-- Prompt Builder -->
        <div v-if="tabs_options === 0">
          <div class="d-flex justify-content-between  mb-05">
            <div class="text-primary cursor-pointer" v-if="getOptionAllUsed() > 0" @click="prompt_selected = $t('search.styleSettings')[0]; clearPromptsSelected()">Reset</div>
          </div>
          
          <div class="d-flex justify-content-between">
            <component :disabled="loader_image_search" v-model="prompt_selected" :is="vue_select" class="w-100 select-options-ai select-options-ai-setting" label="title" :options="$t('search.styleSettings')">
              <template #option="{title, subtitle}">
                <div class="d-flex align-items-center">
                  <div>
                    <!-- <b-icon :icon="getIconsRequest(value)" class="mr-05"></b-icon> -->
                  </div>
                  <div class="d-flex flex-column">
                    <span class="d-block">{{title}}</span>
                    <span class="avenir-medium white-space-pre-wrap">{{subtitle}}</span>
                  </div>
                </div>
              </template>
            </component>    
          </div>
  
          <div 
            class="mt-1 collapse-gray-area" 
            v-for="section in $t('search.promptBuilder')" 
            :key="section.key"
          >
            <div
            @click="toggleCollapse(section.key)"
            class="d-flex justify-content-between align-items-center toggle-hover"
            >
              <div><strong>{{section.text}}</strong> <span class="avenir-medium text-muted" v-if="getOptionUsed(section.value) > 0">({{ getOptionUsed(section.value) }})</span></div>
              <div class="container-plus-less-icon" :ref="`${section.key}-prompt`">
                <span></span>
                <span></span>
              </div>
            </div>
            <b-collapse :id="section.key" v-model="is_open[section.key]">
              <b-row class="mt-1">
                <div v-for="options in section.styles" :key="options.key" class="col-12">
  
                  <component 
                    :is="vue_select"
                    class="w-100 options-select-types select-options-ai" 
                    label="value"
                    :options="options.options"
                    v-model="prompt_builder[section.value].models[options.key]"
                    :reduce="value => value.value"
                    :calculate-position="withPopper"
                    append-to-body
                    :disabled="loader_image_search"
                  >
                    <template #header>
                      <div class="avenir-medium">{{ options.text }}</div>
                    </template>
    
                    <template #no-options="{ search }">
                      <li class="button-add-vue-select" @click="addItem(section.key, options.key, search); search = ''">
                        <div class="">Add item</div>
                      </li>
                    </template>
    
                    <template #option="{ value }">
                      <div class="avenir-medium">{{ value }}</div>
                    </template>
    
                    <template #selected-option="{ value }">
                      <div class="overflow-text">
                        {{value}}
                      </div>
                    </template>
                  </component>
                  <!-- For artist probably logic -->
                  <component
                    :is="vue_select"
                    class="w-100 options-select-types select-options-ai" 
                    label="value"
                    :options="optionsForArtist()"
                    v-model="prompt_builder['artist'].models[$t('search.artists_options').find((op) => op.key === `style_painter`).key]"
                    :reduce="value => value.value"
                    v-if="prompt_builder['artist'].models['genre'] && section.value === 'artist'"
                    :disabled="loader_image_search"
                  >
                    <template #header>
                      <div class="avenir-medium">{{prompt_builder[section.value].models['genre'] === 'Painter' ? $t('ia.image.stylePainter') : $t('ia.image.styleSculptor')  }}</div>
                    </template>
    
                    <template #no-options="{ search }">
                      <li class="button-add-vue-select" @click="addItem('artist', '', search, true); search = ''">
                        <div class="">Add item</div>
                      </li>
                    </template>
    
                    <template #option="{ value }">
                      <div class="avenir-medium">{{ value }}</div>
                    </template>
    
                    <template #selected-option="{ value }">
                      <div class="overflow-text">
                        {{value}}
                      </div>
                    </template>
                  </component>
                </div>
              </b-row>
            </b-collapse>
          </div>
        </div>
        <!-- ---------- -->
  
        <!-- Model options -->
        <div v-if="tabs_options === 1">
          <div class="mb-1 collapse-gray-area">
              <div
                @click="clickCollapse('advance-settings')"
                v-b-toggle.advance_setting
                class="d-flex justify-content-between align-items-center"
              >
                <strong>{{$t('search.advanceSettings')}}</strong>
                <div class="container-plus-less-icon transition-icon-plus transition-form" ref="advance-settings">
                  <span></span>
                  <span></span>
                </div>
              </div>
              <b-collapse id="advance_setting" visible>
                <component
                  :is="vue_select"
                  class="select-options-ai select-options-ai-setting"
                  :options="size_images_model"
                  v-model="setting_selected.size_images_selected"
                  :disabled="loader_image_search"
                >
                  <template #option="{label}">
                    <div class="avenir-medium">{{ label }}</div>
                  </template>
                </component>
              </b-collapse>
          </div>
    
          <div class="collapse-gray-area">
            <div 
              class="d-flex justify-content-between align-items-center"
              v-b-toggle.upload_img 
              @click="clickCollapse('upload-img')"
              >
              <strong>{{$t('search.uploadImage')}}</strong>
              <div class="container-plus-less-icon" ref="upload-img">
                <span></span>
                <span></span>
              </div>
            </div>
            <b-collapse id="upload_img">
              <div class="main-container-preview-file mt-1">
                <div class="container-custom-input-file" v-if="!file_variants_blob">
                  <div class="container-file">
                    <p class="mb-0">{{$t('dashboard.chooseFile')}}</p>
                  </div>
                  <b-form-file 
                    v-model="setting_selected.file_variants" 
                    accept=".png"
                    class="custom-input" 
                    @input="hasChangeFile"
                  >
                  </b-form-file>
                </div>
                <div class="container-blob" v-else>
                  <div class="container-trash-blob" @click="file_variants_blob = null; setting_selected.file_variants = null"> 
                    <feather-icon icon="TrashIcon" class="icon-trash-blob"></feather-icon>
                  </div>
                  <b-img :src="file_variants_blob" class="img-blob"></b-img>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
        <!-- ---- -->
      </div>

      <div v-else >
        <div class="border-bottom mb-2">
          <h6>Aspect Ratio <b-icon id="aspect-ratio-tooltip" icon="info" class="icon-info-tooltip"></b-icon></h6>
          <b-tooltip target="aspect-ratio-tooltip">1:1 ratio is selected by default. Aspect ratio's determine the dimensions of the generated result</b-tooltip>
          <div class="mb-1 collapse-gray-area collapse-area-imagine">
            <component v-model="options_imagine.aspect_ratio" :is="vue_select" class="w-100 select-options-ai select-options-ai-setting" label="title" :options="aspect_ratio">
              <template #option="{ title }">
                <div class="avenir-medium">{{ title }}</div>
              </template>
            </component>          
          </div>          
        </div>
        <div class="border-bottom pb-1 mb-2">
          <h6>{{ $t('ia.image.models') }} <b-icon id="models-tooltip" icon="info" class="icon-info-tooltip"></b-icon></h6>
          <b-tooltip target="models-tooltip">AI art models determine what kind of images the AI art generator can produce, model can´t have a specific style, aesthehic, or understanding that comes from the art style</b-tooltip>
          <div class="collapse-gray-area collapse-area-imagine" @click.stop="toggleContainer('component_models')">
            <div class="avenir-medium">
              <b-img :src="getUrlCategory(options_imagine.model.category)" class="img"></b-img>
              {{ options_imagine.model.name }}
            </div>
            <feather-icon icon="ChevronRightIcon"></feather-icon>
            <dropdown-models
              :models_imagine="models_imagine"
              @select_model="selectModel"
              ref="component_models"
              :name_model="{title: this.$t('ia.image.models'), model: 'model'}"
            ></dropdown-models>
          </div>
        </div>
        
        <div class="border-bottom mb-2">
          <h6>{{$t('ia.image.artStyle')}}<b-icon id="art-styles-tooltip" icon="info" class="icon-info-tooltip"></b-icon></h6>
          <b-tooltip target="art-styles-tooltip">Art syles allow users to generate images using a specific style. Theres are predefined styles made by our team</b-tooltip>

          <div class="mb-1 collapse-gray-area collapse-area-imagine" @click.stop="toggleContainer('component_styles')">
            <div v-if="options_imagine.art_style" class="avenir-medium">
              <b-img :src="getUrlCategory(options_imagine.art_style.category)" class="img"></b-img>
              {{ options_imagine.art_style.name }}
            </div>
            <div v-else class="avenir-medium">
              {{$t('ia.image.addStyle')}}
            </div>
            <feather-icon icon="ChevronRightIcon"></feather-icon>
            <dropdown-models
              :models_imagine="art_style_imagine"
              @select_model="selectModel"
              :name_model="{title: this.$t('ia.image.artStyle'), model: 'art_style'}"
              ref="component_styles"
            ></dropdown-models>
          </div>
          <div class="mb-1 collapse-gray-area collapse-area-imagine">
            {{$t('ia.image.hd')}}
            <b-form-checkbox switch v-model="options_imagine.high_res_results"></b-form-checkbox>
          </div>
        </div>

        <div class="border-bottom pb-1 mb-2">
          <div class="d-flex justify-content-between mb-1">
            <h6>{{$t('ia.image.scaleCfg')}}<b-icon id="cfg-tooltip" icon="info" class="icon-info-tooltip"></b-icon></h6>
            <b-tooltip target="cfg-tooltip">CFG scale adjust how much the image will be like your prompt. Higher values keep your image closer to your prompt. Note: Higher values can reduce the output quelity, giving less room to the AI for being creative</b-tooltip>

            <span class="span-gray-area">{{ options_imagine.cfg }}</span>
          </div>
          <b-form-input id="range-cfg" v-model="options_imagine.cfg" type="range" min="3" max="15" step="0.5"></b-form-input>
        </div>
        
        <div class="border-bottom pb-1 mb-2">
          <div class="d-flex justify-content-between mb-1">
            <h6>{{$t('ia.image.scaleStep')}}<b-icon id="scale-tooltip" icon="info" class="icon-info-tooltip"></b-icon></h6>
            <b-tooltip target="scale-tooltip"></b-tooltip>

            <span class="span-gray-area">{{ options_imagine.steps }}</span>
          </div>
          <b-form-input id="range-steps" v-model="options_imagine.steps" type="range" min="30" max="50" step="1"></b-form-input>
        </div>

        <div>
          <h6>Seed<b-icon id="seed-tooltip" icon="info" class="icon-info-tooltip"></b-icon></h6>
          <b-tooltip target="seed-tooltip">Different numbers result in new variations of your output image.</b-tooltip>

          <b-form-input v-model="options_imagine.seed" type="number"></b-form-input>
        </div>
      </div>

      <b-sidebar shadow backdrop right v-model="open_sidebar_text_prompt" @change="hasClosedSidebar">
        <template #header>
          <div class="p-1 border-bottom d-flex">
            <h4>Learn More: {{ tabs_options === 0  ? 'Prompt Builder' : 'FormAI Options'}} </h4>

            <feather-icon icon="XIcon" class="x-icon-sidebar-info" @click="open_sidebar_text_prompt = false"></feather-icon>
          </div>
        </template>
        <div class="p-2" v-if="tabs_options === 0">
          Our Prompt Builder is a great way to experiment with different image styles that will have you generating amazing results in no time.

The easiest - and most fun - way to get started is to type in your prompt and open the I'm feeling lucky! accordion. Clicking one of the presets - Painting, Photorealistic, Digital Art or Surprise Me will select a variety of options from all the sections. Then all you have to do is click Generate and you'll typically get fantastic image results.

Painting, Photorealistic, or Digital Art - will pick from certain related sections to produce a result that should generally stick to the theme. Surprise Me will pick a random selection of keywords from all the various sections and may produce unexpected results, good or bad.

Each of the sections under Prompt Builder - Camera, Art Style, Artist, Scene, and Technical - have a variety of options that you can use to affect your images, or inspire you to include in your own prompts. Read on to get a full breakdown.

Open the Camera accordion to reveal options for emulating real-life photography.

Hardware includes a variety of physical cameras. Selecting one of these can drastically affect you image results, such as giving them a professional look using DSLR, or adding an air of nostalgia using Instant Camera.
Selecting a Lens can flatten or distort your images and subjects in interesting ways, just as they do in real life.
Aperture mostly affects the focus of your images and can produce extremely sharp to blurry, dream-like results.
Framing can influence the angle and/or how much of the subject you're rendering is shown.
Pro tip: Selecting options from the Camera section are great for reproducing photorealistic images. They might not always make sense when trying to reproduce something artistic, like a painting, using the following sections. But don't let that stop you from experimenting!

Under the Art Style accordion are options for recreating physical mediums or historical style periods and movements.

Both Primary Style and Secondary Style include popular art periods and movements that can strongly influence the overall design of your images.
Perspective can give - or take away - depth to your images.
Medium will make your images look like they were made of a certain material. Think drawings in marker or pen, paintings in watercolor or acrylic, or sculptures made of clay or wood.
Substrate applies mostly to paintings and drawings - it's the material the medium is applied on. Think painting on canvas, graffiti on brick, or even dye on fabric.
Pro tip: Picking two vastly different styles can typically produce interesting, unique results!

The Artist accordion includes options for emulating artists from a wide variety of genres. Due to copyright reasons, we can't include modern day artists, but you are able to add your own! Think of unique digital creators, directors that have a particular style, or inventive film studios - and add these for even more variety.

To keep things organized - since there are quite literally thousands of artists the AI can reproduce - the first dropdown is the Genre of artist. Painter, Photographer, Sculptor, etc.
After selecting a specific genre, you can select (or add) a Style of artist to emulate.
Pro tip: Mixing an artist with a art period or medium they're not known for is a great way to get fun, expected results. What would pop art by Da Vinci look like? A paper sculpture by Picasso? Pixel art by Michelangelo?

In the Scene accordion are a number of options for affecting the feel or mood of a your images.

Palette includes a wide variety of color themes - from dull and muted to vibrant and multihued - that can greatly influence the tone of your images.
Lighting is an important factor in the feel of images and can make your results dark and gloomy or bright and happy.
Aesthetic is actually a broad, intangible term that can affect both the colors and mood of your images. The options here are fun to experiment with!
Emotion is another intangible term that should affect how your image feels or how people respond to your images.
Atmosphere imbues your images with elements from the weather. Is your scene sunny? Cold? Rainy? Foggy?
Adding a Background is great when you have a clearly defined subject but want to experiment with isolating the subject on a solid color, or putting it in unexpected environment like the mountains, or a beach.
Finally, under the Technical accordion are options for varying how your image is rendered.

Resolution is a common term used in TVs and monitors to define sharpness or the appearance of pixels. You can choose from ultra-sharp 8K all the way down to low-res 8-bit.
Renderer is commonly used to refer to how objects and environments are drawn in software or video games. You can affect the overall look of your results from photorealistic to line-drawn.
Website refers to portfolio sites on the internet the AI can use as a reference. We've generalized them here to the overall themes of some of the most popular sites.
That was a lot to cover! Keep in mind this is a small (but effective) set of options. If you want to try something you don't see in the dropdowns, add it to your prompt. The fun part of AI is experimenting with different keywords and combinations!

Final pro tip: Our prompt builder does a lot of this magic behind the scenes, but if you want to really experiment and write your own prompt from scratch - be as descriptive as possible, and the order of terms matters! In general, you want to start with the feel of your scene (A serene, dreamy forest,) then your subject (with a majestic sword plunged in a giant boulder,) then art style and artist (illustration, in the style of [artist],) followed by technical (4K, photorealistic, trending on [website])
        </div>
        <div class="p-2" v-else>
          When using Text to Image to generate an image using FormAI, the Options are important settings that will have a big effect on the final results you see and how many credits you use.

The first - and possibly most important - selection is which Model you would like to use. The various models use different data sets and will interpret your prompt uniquely.

Hypnos is a our most popular model and understands a large variety of inputs and produces extremely high-quality images.
Poseidon is less precise, it still produces good results, but typically faster and supports larger images.
Some models don't support every image size or aspect ratio explained below.

Image Count determines how many results the model generates based on your prompt. While more images will give you greater variety, each image will cost credits based on the settings you choose.

You can click on the credit calculator displayed in the footer for a breakdown of how much each setting costs.

If you're finding the model is generating elements you didn't expect, try adding terms in the Negative Prompt field. For example, if your prompt is enchanted sword in a forest and the results unexpectedly include a knight, you could enter knight (or person might be an even better generic term) as a negative prompt.

Under the Advanced Settings accordion are a number of settings which mostly play into how many credits each image you generate costs.

Aspect Ratio is the proportional relationship between the width and height of the images you'll generate.

Square (1:1) is the default and results in a square image.
Fullscreen (4:3) is more rectangular and was a common aspect ratio of older CRT TVs and computer monitors.
Photo (5:4) is again rectangular and commonly used for photo prints.
Widescreen (16:9) is an extreme rectangle shape and mostly used for modern cinematic, 4K images.
In general, Square (1:1) is the best option, as the AI tends to want to fill in the space inherent of other aspect ratios, causing unexpected results.

Size relates to the physical dimensions of your image, combined with the aspect ratio. For example, selecting an aspect ratio of Photo (5:4) and size of Medium (512px) will result in a 640px wide by 512px tall image. Larger images require more processing and thus cost more credits.

Note: When displaying images in your browser, images may be scaled to fit. Once downloaded they will be saved to your computer in their true size.

Sampling Steps is a technical term for the number of iterations the model takes to generate and refine an image. Less steps will create a blurry, less detailed image and cost less credits. More steps may help with detail but will cost more credits - and after a certain point, may not necessarily improve the image. In general around 30 steps (the default) produces an ideal image.

The Seed is a random number that the model uses as a starting point for generating an image. Each image has a unique seed, and if you want to reproduce an image or create variations, you'll want to take note of the seed. You can copy the seed to your computer's clipboard by clicking on the :plant: icon.

Prompt Strength (sometimes referred to as CFG Scale) adjusts how closely the model will render images to your description. Lowering this number from the default of 7 will increase variety in your results, but may not look like what you were expecting.
        </div>
      </b-sidebar>

    </div>
  </b-card>
</template>

<script>
import { createPopper } from '@popperjs/core';
import { getAssetUrl, getUrlCategory } from '@/libs/utils/urls';
import { getModelsImagine, getArtStyleImagine } from '@/libs/utils/others';

import {
  BFormCheckbox,
  BCard,
  BTooltip,
  BFormInput,
  BButton,
  BCollapse,
  BRow,
  BBadge,
  BSidebar,
  BButtonGroup,
  BImg,
  BFormFile,
  VBToggle,
} from 'bootstrap-vue'

export default {
  name: 'siderRightOptions',
  directives: {
    'b-toggle': VBToggle
  },
  components: {
    BCard,
    BFormCheckbox,
    BImg,
    BFormFile,
    BTooltip,
    BFormInput,
    BRow,
    BCollapse,
    BButton,
    BBadge,
    BButtonGroup,
    BSidebar,
    dropdownModels: () => import('@/views/components/brandme-ai/dropdowns/dropdownModels.vue')
  },
  props: {
    setting_selected: {
      type: Object,
    },
    options_imagine: {
      type: Object,
    },
    prompt_builder: {
      type: Object,
    },
    is_sidebar: {
      type: Boolean,
      default: false,
    },
    loader_image_search: {
      type: Boolean
    }
  },
  data() {
    return {
      models_imagine: getModelsImagine(),
      art_style_imagine: getArtStyleImagine(),
      aspect_ratio: ['1:1', '3:2', '4:3', '3:4', '16:9', '9:16'],
      getUrlCategory,
      vue_select: null,
      is_open: {},
      open_sidebar_text_prompt: false,
      tabs_options: 0,
      number_image: [1],
      vue: this,
      size_images_model: [],
      prompt_selected: null,
      prompts_rules: {
        photorealistic: {
          camera: {
            hardware: 'DSLR',
            lens: '35mm',
          },
          art_style: {
            primary_style: 'Hyperrealistic',
            secondary_style: 'Photorealism',
            medium: 'Photograph',
          },
          technical: {
            renderer: 'Photorealistic',
          }
        },
        painting: {
          art_style: {
            primary_style: '',
            secondary_style: '',
            medium: '',
            perspective: '2D',
            substrate: ''
          },
          artist: {
            genre: 'Painter',
            style_painter: ''
          },
          scene: {
            palette: '',
            lighting: '',
            aesthetic: '',
            atmosphere: '',
            emotion: '',
            background: ''
          },
          technical: {
            website: 'Illustrators',
          }
        },
        digital_art: {
          art_style: {
            primary_style: '',
            secondary_style: '',
            medium: 'Photograph',
            perspective: '3D',
          },
          scene: {
            palette: '',
            lighting: '',
            aesthetic: '',
            atmosphere: '',
            emotion: '',
            background: ''
          },
          technical: {
            resolution: '',
            renderer: '',
            website: '',
          }
        },
      },
      placement: 'top',
      ia_selected_watch_reference: '',
      file_variants_blob: null,
    }
  },
  async mounted() {
    this.vue_select = (await import('vue-select')).default;
  },
  created() {
    this.prompt_selected = this.$t('search.styleSettings')[0]
    this.size_images_model = this.$t('search.size_images');
    if (this.setting_selected.file_variants) this.file_variants_blob = getAssetUrl(this.setting_selected.file_variants)
  },
  methods: {
    hasChangeFile() {
      if (this.setting_selected.file_variants) {
        setTimeout(() => {
          this.file_variants_blob = URL.createObjectURL(this.setting_selected.file_variants);
        },200)
      }
    },
    hasClosedSidebar(value) {
      if (!value) this.open_sidebar_text_prompt = false
    },
    getOneRandom(section, select) {
      let select_options = '';
      const artist_option_selected = this.prompt_builder.artist.models.genre;
      if (select === 'style_painter' && artist_option_selected === 'Painter') {
        select_options = this.$t('search.artists_options').find((i) => i.key === 'style_painter')
      } else {
        select_options = this.$t('search.promptBuilder').find((i) => i.value === section).styles.find(sel => sel.text === select)
      }
      return select_options.options[Math.floor(Math.random() * select_options.options.length)].value
    },
    optionsForArtist() {
      const artist_selected = this.prompt_builder['artist'].models['genre'];
      if (artist_selected && (artist_selected.toLowerCase() === 'painter' || artist_selected.toLowerCase() === 'sculptor')) {
        return this.$t('search.artists_options').find((op) => op.key === `style_${artist_selected.toLowerCase()}`).options;
      }
      return this.$t('search.artists_options').find((op) => op.key === 'style_custom');
    },
    clickCollapse(icon_plus, active_left = false, index) {
      if (active_left) {
        this.verifyButtonActive(index)
      }

      const container_icon = this.$refs[icon_plus];
      container_icon.classList.toggle("transition-icon-plus");
      container_icon.classList.toggle("transition-form");
    },
    verifyButtonActive(index) {
      for (let i = 1; i < 5; i++)  {
        if (i !== index) {
          const iconActivate = this.$refs[`icon_plus_${i}`].classList 
          if (iconActivate.length > 1) {
            iconActivate.toggle("transition-icon-plus");
            iconActivate.toggle("transition-form");
          }
        }
      }
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    },
    clearPromptsSelected() {
      for (const section in this.prompt_builder) {
        for (const select in this.prompt_builder[section].models) {
          this.prompt_builder[section].models[select] = '';
        }
      }
    },
    toggleContainer(name) {
      // Hide dropdowns before open other
      this.$refs['component_models'].$el.classList.add('d-none')
      this.$refs['component_styles'].$el.classList.add('d-none');

      this.$refs[name].$el.classList.toggle('d-none')
    },
    selectModel(value, model) {
      this.options_imagine[model] = value.value ? value : null;
    },
    addItem(section, option, new_value) {
      const array = this.$t('search.promptBuilder').find((sec) => sec.key === section).styles.find((opt) => opt.key === option)
      array.options.unshift({value: new_value})
    },
    getOptionUsed(section) {
      return Object.values(this.prompt_builder[section].models).filter(model => model).length;
    },
    getOptionAllUsed() {
      return Object.values(this.prompt_builder).flatMap(section => Object.values(section.models)).filter(model => model).length;
    },
    toggleCollapse(section) {
      this.is_open[section] = !this.is_open[section]
      const icon_plus_promp = this.$refs[`${section}-prompt`];
      icon_plus_promp[0].classList.toggle("transition-icon-plus");
      icon_plus_promp[0].classList.toggle("transition-form");
    },
    getRandomOptions() {
      const total_sections_label = this.$t('search.promptBuilder').map((i) => i.value)
      total_sections_label.sort(() => 0.5 - Math.random());
      const sections_sliced = total_sections_label.slice(0, Math.floor(Math.random() * total_sections_label.length) + 1)
      
      const random_values = {}
      sections_sliced.forEach((section) => {
        random_values[section] = {};
        const section_object = this.$t('search.promptBuilder').find((i) => i.value === section)
        const total_labels_select = section_object.styles.map((i) => i.key)
        const labels_sliced = total_labels_select.slice(0, Math.floor(Math.random() * total_labels_select.length) + 1)
        
        labels_sliced.forEach((select) => {
          random_values[section][select] = {};
          const select_object = section_object.styles.find((i) => i.key === select)
          const random_value = select_object.options[Math.floor(Math.random() * select_object.options.length)]
          random_values[section][select] = random_value.value;
        })
      })

      return random_values
    },
  },
  watch: {
    prompt_selected(value) {
      if (value.key !== 'default_free') {
        this.clearPromptsSelected();
        const prompts_rules = value.key !== 'surprise' ? this.prompts_rules[value.key] : this.getRandomOptions(); 
        for (const section in prompts_rules) {
          for (const option in prompts_rules[section]) {
            this.prompt_builder[section].models[option] = prompts_rules[section][option].length > 0 ? prompts_rules[section][option] : this.getOneRandom(section, option);
          }
        }
      }
    },
    setting_selected: {
      handler(value) {
        const ia_selected = value.ia_selected.value;
        if (ia_selected !== this.ia_selected_watch_reference) {
          this.size_images_model = (ia_selected !== "dall-e") ? this.$t('search.size_images_xl') : this.$t('search.size_images');
          this.number_image = (ia_selected !== 'imagine') ?  [1, 3, 4, 5, 6, 7, 8, 9, 10] : [1];
          this.setting_selected.size_images_selected = this.size_images_model[0];
          
          this.ia_selected_watch_reference = ia_selected;
        }
      },
      deep: true
    },
  }
}
</script>

<style lang="scss" scoped>
.hvh-100 {
  height: 100%;
  @media(max-width: 500px) {
    height: 90vh;
  }
}
.ml-05 {
  margin-left: 0.5em;
}
.collapse-area-imagine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px !important;
  // position: relative;
  .img {
    width: 26px;
    height: 26px;
    border-radius: 0.6em;
    object-fit: cover;
    margin-right: 0.5em;
  }
 
}


.white-space-pre-wrap {
  white-space: pre-wrap;
}
.overflow-text {
  white-space: nowrap;
  overflow-x: hidden;
  width: 100px;
  text-overflow: ellipsis;
}
.toggle-hover:hover{
  cursor: pointer;
}
.max-height-area-options{
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.collapse-gray-area {
  background: #F8F9FA;
  padding: 1rem ;
  border-radius: 0.7em;
}
.icon-info-tooltip {
  background-color: #eaeaeb;
  margin-left: 0.5em;
  cursor: pointer;
  border-radius: 5px;
  width: 15px;
  height: 15px;
}
.span-gray-area {
  background: #F8F9FA;
  padding: 0.1em 0em;
  border-radius: 0.7em;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-plus-less-icon {
  background: transparent;
  width: 30px;
  height: 30px;
  border: 0;
  position: relative;

  span {
    position: absolute;
    transition: 300ms;
    background: #999999;

    &:first-child {
      top: 25%;
      bottom: 25%;
      width: 10%;
      left: 45%;
    }
    &:last-child {
      left: 25%;
      right: 25%;
      height: 10%;
      top: 45%;
    }
  }
}
.transition-icon-plus {
  span {
    transform: rotate(90deg);

    &:last-child {
      left: 50%;
      right: 50%;
    }
  }
}
.button-add-vue-select {
  color: #28c76f;
  &:hover {
    cursor: pointer;
    background-color: #28c76f;
    color: white !important;
  }
}
.mb-05 {
  margin-bottom: 0.5em;
}
.main-container-preview-file {
  
  .container-file {
    // top: 40px;
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgba(0,0,0,.45);
    height: 50px;
    font-size: 12px;
    cursor: pointer;
    .img-camera {
      width: 20px;
      display: block;
    }
  }
  .container-custom-input-file {
    position: relative;
    height: 50px;
  }
  .container-blob {
    height: 120px;
    background-color: #DEE2E6;
    border-radius: 0.7em;
    position: relative;
   
    .container-trash-blob {
      position: absolute;
      top: 0.5em;
      right: 0.5em;     
      cursor: pointer;
      padding: 0.6em;
      border-radius: 10em;
      
      &:hover {
        background-color: #bdc0c4da;
      }
      .icon-trash-blob {
        color: black;
      }
    }
    .img-blob {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.x-icon-sidebar-info {
  position: absolute;
  top: 1em;
  right: 1em;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
</style>
<style lang="scss">
.custom-input {

  .custom-file-label {
    border: 1px dashed #3483fa !important;
    cursor: pointer;
    color: transparent;
    position: absolute;
    height: 50px !important;
    top: 0px;
    background: transparent !important;

    &:hover {
      border: 2px solid #3483fa !important;
      cursor: pointer;
    }

    &::after {
      display: none;
    }
  }
}
.vs__dropdown-menu {
  z-index: 10000;
}
.select-options-ai-setting .vs__clear {
  display: none;
}
.select-options-ai .vs__selected {
  font-family: 'avenir-medium';
}
#number-image-select .vs__dropdown-menu {
  left: -80px !important;
}
.button-tabs-options-ia {
  background: #e9ecef !important;
  border-radius: 0.7em !important;
  padding: 1.5em !important;

  &:hover  {
    background: #dee2e6 !important;

    &:disabled {
      background: #e9ecef !important;
    }
  }

  &.active {
    background-color: #55a6c4 !important;
    color: white !important
  }
}
</style>